import { Image } from 'mdx-deck';
import FullScreenUI from "../../../../../../assets/Fullscreen_UI.mp4";
import FullScreen from "../../../../../../assets/FullScreen.jpg";
import Dashboard0 from "../../../../../../assets/Dashboard.jpg";
import Dashboard1 from "../../../../../../assets/Dashboard-1.jpg";
import Dashboard2 from "../../../../../../assets/Dashboard-2.jpg";
import Dashboard3 from "../../../../../../assets/Dashboard-3.jpg";
import Type from "../../../../../../assets/SpaceX_Type.mp4";
import Icons from "../../../../../../assets/SpaceX_Icons_03.mp4";
import UIMatch1 from "../../../../../../assets/SpaceX_UIMatch_01.mp4";
import UIMatch2 from "../../../../../../assets/SpaceX_UIMatch_02.mp4";
import Tweets from "../../../../../../assets/Tweets.mp4";
import custom from "../../../../../../theme";
import React from 'react';
export default {
  Image: Image,
  FullScreenUI: FullScreenUI,
  FullScreen: FullScreen,
  Dashboard0: Dashboard0,
  Dashboard1: Dashboard1,
  Dashboard2: Dashboard2,
  Dashboard3: Dashboard3,
  Type: Type,
  Icons: Icons,
  UIMatch1: UIMatch1,
  UIMatch2: UIMatch2,
  Tweets: Tweets,
  custom: custom,
  React: React
};